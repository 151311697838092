jQuery(document).ready(function ($) {
  filterFacialDifferences();
  //AJAX ON CLICK OR SEARCH
  $(".letter").click(function () {
    $(".letter").removeClass("active");
    $(this).addClass("active");
    filterFacialDifferences();
  });
  $(".categories-option").click(function () {
    $(".categories-option").removeClass("active");
    $(this).addClass("active");
    filterFacialDifferences();
  });

  $(".search-button").click(function () {
    searchFacialDifferences();
  });

  $(".clear").click(function () {
    $(".categories-option").removeClass("active");
    $(".letter").removeClass("active");
    $("#filter_search").val("");
    $($(".letter")[0]).addClass("active");
    filterFacialDifferences();
  });

  $("#filter_search").on("keypress", function (e) {
    if (e.which == 13) {
      searchFacialDifferences();
    }
  });

  $(document).on("keypress", function (e) {});

  function searchFacialDifferences() {
    if (!$("#filter_search").val()) return;
    $(".letter").removeClass("active");
    $(".categories-option").removeClass("active");
    ThemeJS.Functions.send_ajax_request({
      data: {
        search: $("#filter_search").val(),
        action: "filter_facial_differences",
      },
      success: (response) => {
        if (response) {
          $(".condition-list").html(response);
          $(".content_list_button").click(function () {
            if (
              $(this).next(".content_list_description").hasClass("show-text")
            ) {
              $(this)
                .next(".content_list_description")
                .removeClass("show-text");
              $(this).find("i").addClass("fa-plus");
              $(this).find("i").removeClass("fa-minus");
              $(this).parent().removeClass("active");
              $(this).attr("aria-expanded", false);
            } else {
              console.log("heree");
              $(this).next(".content_list_description").addClass("show-text");
              $(this).find("i").removeClass("fa-plus");
              $(this).find("i").addClass("fa-minus");
              $(this).parent().addClass("active");
              $(this).attr("aria-expanded", true);
            }
          });
        } else {
          $(".condition-list").html(
            '<p class="text-center">No results found</p>'
          );
        }
      },
    });
  }

  function filterFacialDifferences() {
    $("#filter_search").val("");
    if (!$(".letter.active").html()) {
      $($(".letter")[0]).addClass("active");
    }
    ThemeJS.Functions.send_ajax_request({
      data: {
        action: "filter_facial_differences",
        letter: $(".letter.active").html(),
        type: $(".categories-option.active").data("type"),
        action: "filter_facial_differences",
      },
      success: (response) => {
        if (response) {
          $(".condition-list").html(response);
          $(".content_list_button").click(function () {
            if (
              $(this).next(".content_list_description").hasClass("show-text")
            ) {
              $(this)
                .next(".content_list_description")
                .removeClass("show-text");
              $(this).find("i").addClass("fa-plus");
              $(this).find("i").removeClass("fa-minus");
              $(this).parent().removeClass("active");
              $(this).attr("aria-expanded", false);
            } else {
              console.log("heree");
              $(this).next(".content_list_description").addClass("show-text");
              $(this).find("i").removeClass("fa-plus");
              $(this).find("i").addClass("fa-minus");
              $(this).parent().addClass("active");
              $(this).attr("aria-expanded", true);
            }
          });
        } else {
          $(".condition-list").html(
            '<p class="text-center">No results found</p>'
          );
        }
      },
    });
  }
});
