(function($) {
  jQuery(document).ready(function() {
    /* $(".content_list").click(function () { */
    /*   if ($(this).find(".content_list_description").is(":visible")) { */
    /*     $(this).find(".content_list_description").hide(); */
    /*     $(this).find("i").addClass("fa-plus"); */
    /*     $(this).find("i").removeClass("fa-minus"); */
    /*     $(this).removeClass("active"); */
    /*     $(this).attr("aria-expanded", false); */
    /*   } else { */
    /*     $(this).find(".content_list_description").show(); */
    /*     $(this).find("i").removeClass("fa-plus"); */
    /*     $(this).find("i").addClass("fa-minus"); */
    /*     $(this).addClass("active"); */
    /*     $(this).attr("aria-expanded", true); */
    /*   } */
    /* }); */
  });
})(jQuery);
