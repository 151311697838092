(function ($) {
  jQuery(document).ready(function () {
    jQuery(".slider-section").each((i, item) => {
      $(jQuery(item).find(".slider-carousel")).slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        infinite: false,
        /* autoplay: true, */
        autoplaySpeed: 4000,
        swipe: false,
        prevArrow: jQuery(item).find(".slider-prev-arrow"),
        nextArrow: jQuery(item).find(".slider-next-arrow"),
      });
    });
  });
})(jQuery);
